import React, { useState, useEffect } from "react";
import axios from "axios";
import Spinner from "./components/Spinner"; // スピニングアイコン用のコンポーネント
import ServerErrorIcon from "./components/ServerErrorIcon"; // "SERVER ERROR"アイコン用のコンポーネント
import SearchBar from "./components/SearchBar";
import ImageList from "./components/ImageList";
import logo from "./Search_Logo.png";
import "./ImageHeader.css";

import { Amplify } from "aws-amplify";

import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import awsExports from "./aws-exports";
Amplify.configure(awsExports);

const App = () => {
  const [images, setImages] = useState([]);
  const [sortedIndex, setSortedIndex] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isServerError, setIsServerError] = useState(false);
  const [retryCount, setRetryCount] = useState(0); // APIリクエストの再送回数
  const [shouldRetry, setShouldRetry] = useState(false);

  useEffect(() => {
    if (shouldRetry) {
      const timer = setTimeout(() => {
        checkApiStatus();
        setShouldRetry(false); // リトライ後はリセット
      }, 5000); // リトライまでの時間

      // コンポーネントのアンマウント時にタイマーをクリア
      return () => clearTimeout(timer);
    }
  }, [shouldRetry]); // shouldRetryが変わるたびにこのeffectを実行

  //初回の処理
  useEffect(() => {
    checkApiStatus();
  }, []); // 依存配列を空にすることで、コンポーネントのマウント時にのみ実行される

  const checkApiStatus = async () => {
    try {
      const response = await axios.get(
        "https://api.image-recognition.sawajiri.com/?words=dummy",
        { timeout: 3000 } //3秒でタイムアウト扱い
      );
      console.log("API Response received", response.data);
      setIsLoading(false);
      setIsServerError(false);
      setRetryCount(0);
    } catch (error) {
      console.log(
        `Attempt ${
          retryCount + 1
        }: Failed to get a successful response from API`
      );
      // retryCountが基準に達する前のみリトライをスケジュール
      if (retryCount < 20) {
        // タイムアウトまたは、ステータスコードが502の場合、APIの準備がまだであると判断し、リトライを続ける
        if (
          (error.response && error.response.status === 502) ||
          error.message.includes("timeout") ||
          error.code === "ERR_NETWORK"
        ) {
          console.error("Server not ready yet:", error);
          setIsLoading(true);
          startEc2Instance();
          setRetryCount(retryCount + 1);
          setShouldRetry(true); //リトライのトリガをセット
        } else {
          // その他のエラーの場合は、サーバーエラーとして処理
          console.error("API error:", error);
          setIsLoading(false);
          setIsServerError(true);
        }
      } else {
        setIsLoading(false);
        setIsServerError(true);
        console.error("API did not respond in time, after 10 retries.");
      }
    }
  };

  const startEc2Instance = async () => {
    try {
      const response = await axios.post(
        "https://cmolsk8m20.execute-api.ap-northeast-1.amazonaws.com/start_EC2_instance?instance_id=i-014f40d1d2b074968"
      );
      console.log("EC2 instance start requested", response.data);
    } catch (error) {
      console.error("Failed to start EC2 instance:", error);
      setIsServerError(true);
    }
  };

  const onSearchSubmit = async (term, term2) => {
    console.log("Form Submitted:", term, term2);
    // 検索がサブミットされたときにもEC2インスタンスの起動要求を行う
    // startEc2Instance();
    // 検索処理...
    try {
      const params = {
        words: term,
        images: term2,
        sorted_index: JSON.stringify(sortedIndex),
      };
      const response = await axios.get(
        "https://api.image-recognition.sawajiri.com",
        {
          params,
        }
      );
      setImages(response.data.hits);
      setSortedIndex(response.data.sorted_index);
      if (response.data.total === 0) {
        window.alert("お探しの画像はありません");
      }
    } catch (error) {
      console.error("Error fetching images:", error);
      window.alert("写真の取得に失敗しました。" + error);
    }
  };

  // /* ログインなし
  return (
    <main>
      <div className="ui container">
        <div className="search-header">
          <SearchBar onSubmit={onSearchSubmit} />
        </div>
        {isLoading && <Spinner />}
        {isServerError && <ServerErrorIcon />}
        <ImageList images={images} />
      </div>
    </main>
  );
  // */

  /*ログイン付き
  return (
    <Authenticator>
      {({ signOut, user }) => (
        <main>
          <div className="ui container" style={{ marginTop: "20px" }}>
            <div className="search-header">
              <img src={logo} alt="search-logo" className="search-logo" />
              <SearchBar onSubmit={onSearchSubmit} />
              <button onClick={signOut} className="sign-out-button">
                Sign out
              </button>
            </div>
            {isLoading && <Spinner />}
            {isServerError && <ServerErrorIcon />}
            <ImageList images={images} />
          </div>
        </main>
      )}
    </Authenticator>
  );
  */
};

export default App;
