import React from "react";
import Masonry from "react-masonry-component";
import "../styles/ImageList.css";

const ImageList = (props) => {
  const images = props.images.map((image) => {
    return (
      // <a href={image.pageURL} key={image.id} target='_blank' rel='noopener noreferrer' className='ui medium image'>
      // <img src = {image.webformatURL} alt={image.tags} />
      // </a>
      <div key={image.id} className="image-element">
        <p className="image-tag"> {image.id} </p>
        <a
          href={image.pageURL}
          target="_blank"
          rel="noopener noreferrer"
          className="ui medium image"
        >
          <img src={image.webformatURL} alt={image.tags} />
        </a>
      </div>
    );
  });
  return <Masonry className="image-list">{images}</Masonry>;
  //   return (
  //     <Masonry
  //       className="image-list"
  //       elementType={"div"} // デフォルトの 'div' を明示的に設定
  //       options={{ transitionDuration: 0 }} // トランジションの無効化
  //       updateOnEachImageLoad={true} // 画像が読み込まれるたびにレイアウトを更新
  //     >
  //       {images}
  //     </Masonry>
  //   );
};

export default ImageList;
