import React, { useState } from "react";
import Spinner from "./Spinner";
import "../styles/SearchBar.css";

const SearchBar = ({ onSubmit, isLoading }) => {
  const [term, setTerm] = useState("");
  const [term2, setTerm2] = useState("");

  const onFormSubmit = (event) => {
    event.preventDefault();
    console.log("Submitting form with: ", term, term2); // コンソール出力を追加
    onSubmit(term, term2);
  };

  return (
    <div className="search-bar">
      <form onSubmit={onFormSubmit} className="ui form">
        <div className="field">
          <div className="search-field">
            <label>テキスト</label>
            <input
              id="text-search"
              type="text"
              name="term"
              value={term}
              placeholder="検索したい画像をテキストで入力"
              onChange={(event) => setTerm(event.target.value)}
            />
          </div>
          <div className="search-field">
            <label>画像番号</label>
            <input
              id="image-search"
              type="text"
              name="term2"
              value={term2}
              placeholder="検索したい画像を画像No.で入力"
              onChange={(event) => setTerm2(event.target.value)}
            />
          </div>
          <div className="button-container">
            {isLoading ? <Spinner /> : ""}
            <button
              type="submit"
              className={`search-button ${isLoading ? "hidden" : ""}`}
            >
              検索
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SearchBar;
