import React from "react";
import "../styles/ServerErrorIcon.css"; // エラーアイコンのスタイルを定義するCSSファイルをインポート

const ServerErrorIcon = () => (
  <div className="server-error-container">
    <div className="server-error-icon">⚠️</div>
    <p className="server-error-text">SERVER ERROR</p>
  </div>
);

export default ServerErrorIcon;
